html,body { font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;}

.heroPhotoElement img { border: none; }

hr.hrElement {
	background: #ddd;
	height: 2px;
	right:0px;
	width: auto; }

/* TOP NAV */
#topNav {
	font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
	font-weight: 300; }

#topNav .theme-main-nav {
	background-color: #f2f3f4;
	border-bottom: 1px solid #fff; }

#topNav .theme-main-nav .theme-nav > .theme-nav-item > .theme-nav-link {
	color: #68767f; }

#topNav .theme-main-nav .theme-nav > .theme-nav-item > .theme-nav-link:hover {
	color: #120a09; }

#topNav .theme-main-nav .theme-nav > .theme-nav-item > .theme-nav-link:not(x):hover {
	background-color: transparent; }

#topNav .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item > .theme-nav-link:not(x) {
	color: #68767f;
	background:#f2f3f4; }

#topNav .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item > .theme-nav-link:not(x):hover {
	color: #120a09;
	background: #f2f3f4; }

#topNav .theme-nav-style-dropdown ~ .theme-nav-dropdown {
	border-top: 1px solid #68767f;
	border-bottom: none; }

#topNav .theme-nav-style-dropdown > .theme-nav-title a {
    color: #68767f;
    font-weight: 500; }

#topNav .theme-sub-nav .theme-nav > .theme-nav-item.disabled > .theme-nav-link {
    color: #24282b;
    font-weight: 300; }

#topNav .theme-nav-style-dropdown > .theme-nav-item.hasChild > .theme-nav-link:before {
	display: none; }

/* TEXT BLOCK */

.textBlockElement h1, .textBlockElement h2, .textBlockElement h3, .textBlockElement h4, .textBlockElement h5, .textBlockElement h6, .pageEl .textBlockElement blockquote {
	font-family: "Gotham SSm A", "Gotham SSm B", sans-serif; }

.textBlockElement h3, .pageElement h3 {
	color: #24282b !important;
	font-size: 20px;
	background: none;
    box-shadow: none;
    text-transform: uppercase !important;
    padding: 0; }
    
.textBlockElement p, .comment-content p {
    color: #24282b !important;
	font-size: 20px;
	background: none;
    box-shadow: none;
    text-transform: none !important;
    padding: 0; 
    }

.textBlockElement h2 {
	font-size: 20px;
	line-height: 30px;
	color: #68767f;
	text-transform: uppercase;
	font-weight: 300; }

.textBlockElement h1 {
	font-size: 36px;
	line-height:42px;
	text-transform: uppercase;
	color: #24282c;
	font-weight: 500; }

.textBlockElement h4 {
	font-size: 14px;
	line-height: 20px;
	color: #24282c;
	text-transform: uppercase; }

.textBlockElement h5 {
	font-size: 14px;
	line-height: 20px;
	color: #68767f;
	text-transform: uppercase; }

.textBlockElement h6 {
	font-size: 14px;
	line-height: 20px;
	color: #24282c;
	font-weight: 800;
	text-transform: none; }

blockquote:before, blockquote:after { color: #68767f; }

blockquote:before {
	margin-right: -10px;
	position: relative;
	left: -2%; }

blockquote:after {
	right: -2%;
	position: relative; }

.pageEl .textBlockElement blockquote {
	font-size: 20px;
	line-height: 30px;
	font-weight: 300;
	text-transform: uppercase;
	font-style: normal;
	width: auto; }

.textBlockElement p, .comment-content p { line-height: 30px; }

.pageEl .textBlockElement .text > ol, .pageEl .textBlockElement .text > ul {
	font: 20px/30px "Gotham SSm A", "Gotham SSm B", sans-serif;
	color: #24282b;	}

.textBlockElement a { color: #008bc3;}

/* LINKS */
.linkElement .goToLink:after, .linkElement .emailLink:after { display: none; }

.green-link .linkElement h4 a {
	background: #00a846; }
.green-link .linkElement h4 a:hover {
	background: #00873b; }

.red-link .linkElement h4 a {
	background: #b42846; }
.red-link .linkElement h4 a:hover {
	background: #931d38; }

.blue-link .linkElement h4 a {
	background: #008bc3; }
.blue-link .linkElement h4 a:hover {
	background: #00709d; }

.purple-link .linkElement h4 a {
	background: #5a5a95; }
.purple-link .linkElement h4 a:hover {
	background: #49487a; }

.orange-link .linkElement h4 a {
	background: #e06328; }
.orange-link .linkElement h4 a:hover {
	background: #b14e1b; }

.yellow-link .linkElement h4 a {
	background: #efb428; }
.yellow-link .linkElement h4 a:hover {
	background: #ba8e1b; }

.linkElement h4 a, .linkElement h4 a:hover {
	box-shadow: none;
	line-height:1.25em;
	font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 20px;
    color: #fff !important;
	font-weight: 400;
	text-align: center;
	border-radius: 8px;
	text-transform: none; }

/* HEADERS */
.blue-sm .textBlockElement h3 {
    background: #008bc3;
    line-height: 14px;
    font-size: 12px;
    color: white !important;
    padding: 5px 10px;
    display: inline; }

.red-sm .textBlockElement h3 {
    background: #b42846;
    line-height: 14px;
    font-size: 12px;
    color: white !important;
    padding: 5px 10px;
    display: inline; }

.orange-sm .textBlockElement h3 {
    background: #e06328;
    line-height: 14px;
    font-size: 12px;
    color: white !important;
    padding: 5px 10px;
    display: inline; }

.yellow-sm .textBlockElement h3 {
    background: #efb428;
    line-height: 14px;
    font-size: 12px;
    color: white !important;
    padding: 5px 10px;
    display: inline; }

.green-sm .textBlockElement h3 {
    background: #00a846;
    line-height: 14px;
    font-size: 12px;
    color: white !important;
    padding: 5px 10px;
    display: inline; }

.purple-sm .textBlockElement h3 {
    background: #5a5a95;
    line-height: 14px;
    font-size: 12px;
    color: white !important;
    padding: 5px 10px;
    display: inline; }

/* COMMENT STREAM */
.button-medium, .pill-medium-left, .pill-medium-middle, .pill-medium-right, .topNav [class*="button-"]:not(.button-remove):not(.button-add):not(.button-construction), .siteContainer [class*="button-"]:not(.button-remove):not(.button-add):not(.button-construction) {
	background: #00a846;
	color: white;
	border-bottom: none;
	font: 20px/2em "Gotham SSm A", "Gotham SSm B", sans-serif;
	font-weight: 300;
	text-align: center;
	border-radius: 8px;
	text-transform: none; }

.button-medium:hover, .pill-medium-left:hover, .pill-medium-middle:hover, .pill-medium-right:hover, .topNav [class*="button-"]:not(.button-remove):not(.button-add):not(.button-construction):hover, .siteContainer [class*="button-"]:not(.button-remove):not(.button-add):not(.button-construction):hover {
	background: #00873b; }

#commentRequest p {
    font: 14px/28px "Gotham SSm A", "Gotham SSm B", sans-serif;
    color: #24282b; }

.subcomment {
	border-top-width:2px !important;
	border-image: none;
	border-style: solid; }

.comment-content .timeago {
	font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
	font-size: 12px;
	line-height: 14px;
	color: #68767f;
	text-transform: uppercase; }

.comment-content .comment-links a {
	font-weight: 300;
	color: #5a5a95; }

/* CTAs */
.sn-call-to-action { padding: 0 0 5px 0; }

.sn-call-to-action .sn-call-to-action-title, .sn-call-to-action .sn-call-to-action-subtitle { font-family: "Gotham SSm A", "Gotham SSm B", sans-serif; }

.sn-call-to-action .sn-call-to-action-subtitle {
	font-size: 14px;
	text-transform: uppercase; }

.sn-call-to-action:before, .sn-call-to-action:after { display: none; }

.sn-call-to-action .sn-call-to-action-overlay { bottom: 5px; }

/* Custom class to match community site style cards */
/* set cta transition to none */

.custom-cta .sn-call-to-action {
	display: flex;
	flex-flow: column;
	box-shadow: 0 3px 4px 0 rgba(104, 118, 127, 0.3);
	padding: 0 0 5px;
	transition: 0.3s ease;
	min-height: 375px;
}

.custom-cta .sn-call-to-action:hover {
	box-shadow: 0 3px 6px 2px rgba(104, 118, 127, 0.3);
}

.custom-cta .sn-call-to-action .sn-call-to-action-overlay {
	position: relative;
}

.custom-cta .sn-call-to-action .sn-call-to-action-title {
	color: #68767f;
	font-size: 21px;
	line-height: 24px;
	font-weight: 300;
  text-align: left;
  text-transform: none;
}

.custom-cta img {
	width: 100%;
	max-width: 100%;
	min-height: 250px;
	object-fit: cover;
	padding: 0;
	margin: 0;
}
.custom-cta .sn-call-to-action .sn-call-to-action-subtitle a,
.custom-cta .sn-call-to-action .sn-call-to-action-subtitle span {
	color: #68767f;
	font-size: 21px;
	line-height: 24px;
	font-weight: 300;
	text-align: left;
  margin: 0;
  text-transform: none;
}

.custom-cta  .sn-call-to-action .sn-call-to-action-subtitle {
	color: #68767f;
	font-size: 21px;
	line-height: 24px;
	font-weight: 300;
	text-align: left;
  text-transform: none;
}


@media only screen and (min-width: 1024px) {

  .custom-cta .sn-call-to-action {
    min-height: 435px;
  }
 }